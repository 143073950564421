import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components/macro";

import {
  Button,
  CircularProgress,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid as MuiGrid,
  LinearProgress as MuiLinearProgress,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

import { apiurl } from "../../config.js";

const axios = require("axios").default;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const Chip = styled(MuiChip)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Grid = styled(MuiGrid)(spacing);

const LinearProgress = styled(MuiLinearProgress)(spacing);

const Spacer = styled.div(spacing);

const Typography = styled(MuiTypography)(spacing);

const Centered = styled.div`
  text-align: center;
`;

const ExportWarrantyButton = ({ year }) => {
  const [isLoading, setLoading] = React.useState(false);

  function generateFileDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const day = String(today.getDate()).padStart(2, "0");
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");
    const seconds = String(today.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  }

  function handleSubmit() {
    setLoading(true);

    axios
      .get(apiurl + "/export/warranty-tickets/" + year + "/", {
        responseType: "blob",
      })
      .then((response) => {
        setLoading(false);

        /* Get the filename from the content-disposition header */
        var filename =
          year + "-warranty-export" + "_" + generateFileDate() + ".xlsx";

        var data = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        var csvURL = window.URL.createObjectURL(data);
        var tempLink = document.createElement("a");
        tempLink.href = csvURL;
        tempLink.setAttribute("download", filename);
        tempLink.click();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  if (isLoading) {
    return (
      <Button variant="contained" color="primary" disabled>
        <CircularProgress
          size={15}
          style={{ marginLeft: 50, marginRight: 50 }}
        />
      </Button>
    );
  } else {
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSubmit()}
      >
        {year}
      </Button>
    );
  }
};

const ExportWarrantyButtons = () => {
  function getLastFiveYears() {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear - 4; i <= currentYear; i++) {
      years.push(i);
    }
    return years;
  }

  const years = getLastFiveYears();

  return (
    <Grid container spacing={2}>
      {years.map((year) => (
        <Grid item key={"warranty-export-button-" + year}>
          <ExportWarrantyButton year={year} />
        </Grid>
      ))}
    </Grid>
  );
};

const ExportData = () => {
  return (
    <React.Fragment>
      <Helmet title="Export Data" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Export Data
          </Typography>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Warranty Data
          </Typography>
          <ExportWarrantyButtons />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ExportData;
