import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import Page404 from "./pages/auth/Page404";
import Page500 from "./pages/auth/Page500";
import ResetPassword from "./pages/auth/ResetPassword";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";

// Components

// DMG Pages
import ProductAdd from "./pages/RMA/products/add";
import ProductEdit from "./pages/RMA/products/edit";
import ProductList from "./pages/RMA/products/list";
import RMARequest from "./pages/RMA/request";
import TicketLookup from "./pages/RMA/tickets/TicketLookup";
import ExportData from "./pages/export/ExportData";
import Warranty from "./pages/presentation/Landing/WarrantyInfo";

// Form components

// Icon components

// Page components

// Table components

// Documentation

// Landing
import Landing from "./pages/presentation/Landing";

// Protected routes
import StandardLayout from "./layouts/Standard";
import ApproveTicket from "./pages/RMA/actions/Approve";
import ApproveService from "./pages/RMA/actions/ApproveService";
import CompleteService from "./pages/RMA/actions/CompleteService";
import Evaluate from "./pages/RMA/actions/Evaluate";
import Receive from "./pages/RMA/actions/Receive";
import Return from "./pages/RMA/actions/Return";
import CreateIssue from "./pages/RMA/issues/CreateIssue";
import IssueList from "./pages/RMA/issues/ListIssues";
import UpdateIssue from "./pages/RMA/issues/UpdateIssue";
import ManufacturerAdd from "./pages/RMA/manufacturers/add";
import ManufacturerEdit from "./pages/RMA/manufacturers/edit";
import ManufacturerList from "./pages/RMA/manufacturers/list";
import TicketDetail from "./pages/RMA/tickets/TicketDetails";
import TicketList from "./pages/RMA/tickets/TicketList";
import FAQ from "./pages/presentation/Landing/FAQ";
import Troubleshooting from "./pages/presentation/Landing/Troubleshooting";

// Dashboard components
const Default = async(() => import("./pages/dashboards/Default"));
const Analytics = async(() => import("./pages/dashboards/Analytics"));
const SaaS = async(() => import("./pages/dashboards/SaaS"));

// Form components
const Pickers = async(() => import("./pages/forms/Pickers"));
const Editors = async(() => import("./pages/forms/Editors"));
const Formik = async(() => import("./pages/forms/Formik"));

// Icon components
const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
const Profile = async(() => import("./pages/pages/Profile"));
const Tasks = async(() => import("./pages/pages/Tasks"));
const Calendar = async(() => import("./pages/pages/Calendar"));

// Table components
const DataGrid = async(() => import("./pages/tables/DataGrid"));

// Chart components
const Chartjs = async(() => import("./pages/charts/Chartjs"));

// Maps components
const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const routes = [
  {
    path: "/",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <Landing />,
      },
    ],
  },
  {
    path: "faq",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <FAQ />,
      },
    ],
  },
  {
    path: "troubleshooting",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <Troubleshooting />,
      },
    ],
  },
  {
    path: "warranty",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <Warranty />,
      },
    ],
  },
  {
    path: "rma",
    element: <StandardLayout />,
    children: [
      {
        path: "",
        element: <RMARequest />,
      },
      {
        path: "request",
        element: <RMARequest />,
      },
    ],
  },
  {
    path: "tickets",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TicketList />,
      },
      {
        path: "active",
        element: <TicketList status="active" />,
      },
      {
        path: "resolved",
        element: <TicketList status="resolved" />,
      },
    ],
  },
  {
    path: "ticket",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <TicketList />,
      },
      {
        path: "create",
        element: <RMARequest />,
      },
      {
        path: "lookup",
        element: <TicketLookup />,
      },
      {
        path: ":id",
        element: <TicketDetail />,
      },
      {
        path: ":id/approve",
        element: <ApproveTicket />,
      },
      {
        path: ":id/receive",
        element: <Receive />,
      },
      {
        path: ":id/evaluate",
        element: <Evaluate />,
      },
      {
        path: ":id/completeservice",
        element: <CompleteService />,
      },
      {
        path: ":id/return",
        element: <Return />,
      },
    ],
  },
  {
    path: "ticket/:id/approveservice",
    element: <ApproveService />,
  },
  {
    path: "issues",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <IssueList />,
      },
      {
        path: ":id",
        element: <UpdateIssue />,
      },
      {
        path: "new",
        element: <CreateIssue />,
      },
    ],
  },
  {
    path: "products",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProductList />,
      },
    ],
  },
  {
    path: "product",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProductList />,
      },
      {
        path: ":id",
        element: <ProductEdit />,
      },
      {
        path: "add",
        element: <ProductAdd />,
      },
    ],
  },
  {
    path: "manufacturers",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ManufacturerList />,
      },
    ],
  },
  {
    path: "manufacturer",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ManufacturerList />,
      },
      {
        path: ":id",
        element: <ManufacturerEdit />,
      },
      {
        path: "add",
        element: <ManufacturerAdd />,
      },
    ],
  },
  {
    path: "export",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ExportData />,
      },
    ],
  },
  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "404",
        element: <Page404 />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
