import {
  AlertCircle,
  Archive,
  CheckSquare,
  Grid,
  Inbox,
  Package,
  Plus,
  Search,
  TrendingUp,
} from "react-feather";

function ticketSection(summary) {
  return [
    {
      href: "/tickets/active",
      icon: Inbox,
      title: "Open",
      badge: typeof summary === "undefined" ? "-" : summary.active.toString(),
    },
    {
      href: "/tickets/resolved",
      icon: CheckSquare,
      title: "Resolved",
      badge: typeof summary === "undefined" ? "-" : summary.resolved.toString(),
    },
    {
      href: "/ticket/lookup",
      icon: Search,
      title: "Lookup",
    },
    {
      href: "/ticket/create",
      icon: Plus,
      title: "Create New",
    },
    {
      href: "/tickets",
      icon: Archive,
      title:
        "All Tickets (" +
        (typeof summary === "undefined" ? "-" : summary.total.toString() + ")"),
    },
    /*
    {
      href: "/tickets",
      title: "Device History",
      icon: List,
    },
    */
  ];
}

const issueSection = [
  {
    href: "/issues",
    icon: AlertCircle,
    title: "List Issues",
  },
];

const productSection = [
  {
    href: "/products",
    icon: Grid,
    title: "Products",
  },
  {
    href: "/manufacturers",
    icon: Grid,
    title: "Manufacturers",
  },
];

const toolsSection = [
  {
    href: "/export",
    icon: TrendingUp,
    title: "Export Data",
  },
];

const shipmentSection = [
  {
    href: "/shipments",
    icon: Package,
    title: "Shipments",
  },
  {
    href: "/shipments/new",
    icon: Plus,
    title: "Add",
  },
];

function navItems(summary) {
  return [
    {
      title: "Tickets",
      pages: ticketSection(summary),
    },
    {
      title: "Issues",
      pages: issueSection,
    },
    {
      title: "Products",
      pages: productSection,
    },
    {
      title: "Tools",
      pages: toolsSection,
    },
  ];
}

export default navItems;
